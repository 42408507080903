define("discourse/plugins/discourse-custom-topic-lists/discourse/controllers/list", ["exports", "@glimmer/tracking", "@ember/controller", "@ember/object", "@ember/service", "discourse/lib/bulk-select-helper"], function (_exports, _tracking, _controller, _object, _service, _bulkSelectHelper) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  class ListController extends _controller.default {
    static #_ = (() => dt7948.g(this.prototype, "currentUser", [_service.service]))();
    #currentUser = (() => (dt7948.i(this, "currentUser"), void 0))();
    static #_2 = (() => dt7948.g(this.prototype, "modal", [_service.service]))();
    #modal = (() => (dt7948.i(this, "modal"), void 0))();
    static #_3 = (() => dt7948.g(this.prototype, "router", [_service.service]))();
    #router = (() => (dt7948.i(this, "router"), void 0))();
    static #_4 = (() => dt7948.g(this.prototype, "model", [_tracking.tracked]))();
    #model = (() => (dt7948.i(this, "model"), void 0))();
    bulkSelectHelper = (() => new _bulkSelectHelper.default(this))();
    get canBulkSelect() {
      return this.currentUser?.canManageTopic || this.showDismissRead;
    }
    get showDismissRead() {
      return this.model.topics.length > 0;
    }
    dismissRead(dismissTopics) {
      const operationType = dismissTopics ? "topics" : "posts";
      this.bulkSelectHelper.dismissRead(operationType);
    }
    static #_5 = (() => dt7948.n(this.prototype, "dismissRead", [_object.action]))();
  }
  _exports.default = ListController;
});