define("discourse/plugins/discourse-custom-topic-lists/discourse/connectors/above-main-container/custom-topic-lists-banner", ["exports", "@glimmer/component", "@ember/service", "discourse/components/cook-text", "discourse/helpers/body-class", "discourse-common/helpers/d-icon", "@ember/component", "@ember/template-factory"], function (_exports, _component, _service, _cookText, _bodyClass, _dIcon, _component2, _templateFactory) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  class CustomTopicListsBanner extends _component.default {
    static #_ = (() => dt7948.g(this.prototype, "router", [_service.service]))();
    #router = (() => (dt7948.i(this, "router"), void 0))();
    static #_2 = (() => dt7948.g(this.prototype, "site", [_service.service]))();
    #site = (() => (dt7948.i(this, "site"), void 0))();
    get customTopic() {
      if (!this.router.currentRoute.params.topicListName) {
        return;
      }
      return this.site.custom_topic_lists.find(list1 => list1.slug === this.router.currentRoute.params.topicListName);
    }
    static #_3 = (() => (0, _component2.setComponentTemplate)((0, _templateFactory.createTemplateFactory)(
    /*
      
        {{#if this.customTopic}}
          {{! These classes are reflection of discourse-category-banners at category-banner.hbs }}
          {{! https://github.com/discourse/discourse-category-banners/javascripts/discourse/components/category-banner.hbs }}
          {{bodyClass "category-header"}}
          <div class="category-title-header">
            <div class="category-title-contents">
              <div class="category-logo aspect-image"></div>
              <h1 class="category-title">
                {{#if this.customTopic.icon}}
                  <div class="category-icon-widget-wrapper">
                    <div class="category-icon-widget">
                      <span class="category-icon">{{icon
                          this.customTopic.icon
                        }}</span>
                    </div>
                  </div>
                {{/if}}
                {{this.customTopic.name}}
              </h1>
              <div class="category-title-description">
                <CookText @rawText={{this.customTopic.description}} />
              </div>
            </div>
          </div>
        {{/if}}
      
    */
    {
      "id": "IXWQK2lB",
      "block": "[[[1,\"\\n\"],[41,[30,0,[\"customTopic\"]],[[[1,\"      \"],[1,[28,[32,0],[\"category-header\"],null]],[1,\"\\n      \"],[10,0],[14,0,\"category-title-header\"],[12],[1,\"\\n        \"],[10,0],[14,0,\"category-title-contents\"],[12],[1,\"\\n          \"],[10,0],[14,0,\"category-logo aspect-image\"],[12],[13],[1,\"\\n          \"],[10,\"h1\"],[14,0,\"category-title\"],[12],[1,\"\\n\"],[41,[30,0,[\"customTopic\",\"icon\"]],[[[1,\"              \"],[10,0],[14,0,\"category-icon-widget-wrapper\"],[12],[1,\"\\n                \"],[10,0],[14,0,\"category-icon-widget\"],[12],[1,\"\\n                  \"],[10,1],[14,0,\"category-icon\"],[12],[1,[28,[32,1],[[30,0,[\"customTopic\",\"icon\"]]],null]],[13],[1,\"\\n                \"],[13],[1,\"\\n              \"],[13],[1,\"\\n\"]],[]],null],[1,\"            \"],[1,[30,0,[\"customTopic\",\"name\"]]],[1,\"\\n          \"],[13],[1,\"\\n          \"],[10,0],[14,0,\"category-title-description\"],[12],[1,\"\\n            \"],[8,[32,2],null,[[\"@rawText\"],[[30,0,[\"customTopic\",\"description\"]]]],null],[1,\"\\n          \"],[13],[1,\"\\n        \"],[13],[1,\"\\n      \"],[13],[1,\"\\n\"]],[]],null],[1,\"  \"]],[],false,[\"if\"]]",
      "moduleName": "/var/www/discourse/app/assets/javascripts/discourse/discourse/plugins/discourse-custom-topic-lists/discourse/connectors/above-main-container/custom-topic-lists-banner.js",
      "scope": () => [_bodyClass.default, _dIcon.default, _cookText.default],
      "isStrictMode": true
    }), this))();
  }
  _exports.default = CustomTopicListsBanner;
});