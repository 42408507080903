define("discourse/plugins/discourse-custom-topic-lists/discourse/custom-topic-lists-route-map", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = _default;
  function _default() {
    this.route("discovery", {
      path: "/",
      resetNamespace: true
    }, function () {
      this.route("list", {
        path: "/lists/*topicListName",
        resetNamespace: true
      });
    });
  }
});